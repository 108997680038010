
ul.targets {
	list-style: none;
	padding: 0;

	li {
		margin-bottom: 2px !important;
		border: solid 1px transparent;
	}
}
.q-input {
	width: 100%;
}
.q-field__control {
	.q-item {
		width: 100%;
	}
}
